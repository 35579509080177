body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  color: #e0e0e0;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 40
}

button {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 1em;
  background-color: #e0e0e0;
  border: none;
  border-radius: 16px;
  padding: 8px 16px;
  box-shadow: 0px 0px 4px #e0e0e0;
  transition: background-color 0.1s ease-in-out;
}

button:active:not(:disabled) {
  background-color: #b0b0b0;
}

button:disabled {
  opacity: 0.75;
}

input {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 1em;
  color: #e0e0e0;
  background-color: transparent;
  border: solid 2px #e0e0e0;
  border-radius: 16px;
  padding: 8px 16px;
  box-shadow: 0px 0px 4px #e0e0e0;
}

input:focus {
  outline: none;
}

input:disabled {
  opacity: 0.75;
}

a:link { color: #e0e0e0; text-decoration: none; }
a:visited { color: #e0e0e0; text-decoration: none; }
a:hover { color: #e0e0e0; text-decoration: none; }
a:active { color: #e0e0e0; text-decoration: none; }

.shake-on-hover:hover {
  animation: shake 0.5s;
  animation-delay: 0.5s;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-2.5deg); }
  50% { transform: rotate(2.5deg); }
  100% { transform: rotate(0deg); }
}

select {
  font-family: Quicksand;
  font-size: 1em;
  font-weight: 500;
}

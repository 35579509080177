.ranking-header {
  margin-top: 64px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;
  font-size: 1.5em;
  font-weight: 600;
}

.ranking-container {
  max-width: 500px;
  width: 90%;
  margin: auto;
  padding-bottom: 96px;
}

.ranking-container ol {
  font-size: 1.15em;
  font-weight: 500;
}

.ranking-container ol li {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ffffff44;
  margin-bottom: 4px;
  padding-bottom: 8px;
}

.ranking-container ol a:last-child li {
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.ranking-container .entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
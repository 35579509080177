.customize-page {
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.customize-page .customize-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.customize-container .preview-container {
  flex: 1;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}

.customize-container .options-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-bottom: 64px;
  gap: 8px;
}

.customize-container .customization-warning {
  text-align: center;
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 0, 0.2);
}

@media (min-width: 800px) {
  .customize-page {
    display: flex;
    width: 100vw;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  
  .customize-page .customize-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .customize-container .preview-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .customize-container .options-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 8px;
  }
}

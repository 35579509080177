.activity-indicator-size {
  width: 48px;
  height: 48px;
}

.activity-indicator-size div {
  width: 48px;
  height: 48px;
}

.activity-indicator {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  animation-name: activityIndicatorAnimation;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.activity-indicator:nth-child(2) {
  animation-delay: 1s;
  transform: scale(0);
}

@keyframes activityIndicatorAnimation {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
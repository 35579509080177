.modal-backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop .modal-content {
  width: 80vw;
  max-width: 600px;
  margin: auto;
  background-color: #333333;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
}

.modal-backdrop .modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 16px;
}

.modal-backdrop .modal-content .modal-header .modal-title {
  font-size: 1.1em;
  font-weight: 600;
  margin: auto 0px;
}

.modal-backdrop .modal-content .modal-header .modal-close {
  cursor: pointer;
  padding: 4px;
}

.modal-backdrop .modal-content .modal-header .modal-close:hover {
  background-color: #ffffff55;
  border-radius: 8px;
}